import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService { 
  constructor(@Inject(DOCUMENT) private doc) {}

  createLinkForCanonicalURL() {
    let existingCanonicalLink: HTMLLinkElement = this.doc.querySelector('link[rel="canonical"]');
    
    if (!existingCanonicalLink) {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.rel = 'canonical';
      link.href = this.doc.URL;
      this.doc.head.appendChild(link);
    } else {
      existingCanonicalLink.href = this.doc.URL;
    }
  }
}
